define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "common": {
      "actions": {
        "cancel": "Cancel",
        "close": "Fermer",
        "continue": "Continuer",
        "delete": "Supprimer",
        "quit": "Quitter",
        "update": "Modifier"
      },
      "api-error-messages": {
        "bad-request-error": "The data entered was not in the correct format.",
        "gateway-timeout-error": "The service is being slow. Please try again later.",
        "internal-server-error": "An error occurred, our teams are working on finding a solution. Please try again later.",
        "login-unauthorized-error": "There was an error in the email address or username/password entered.",
        "login-user-blocked-error": "Your account has reached the maximum number of failed login attempts and has been temporarily blocked. Please <a href=\"{url}\">contact us</a> to unblock it.",
        "login-user-temporary-blocked-error": "You have reached too many failed login attempts. Please try again later or <a href=\"{url}\">reset your password here</a>."
      },
      "form": {
        "mandatory-all-fields": "All fields are required."
      }
    },
    "current-lang": "en",
    "navigation": {
      "footer": {
        "a11y": "Accessibilité : partiellement conforme",
        "current-year": "© {currentYear} Pix",
        "legal-notice": "Mentions légales"
      }
    },
    "pages": {
      "login": {
        "accessible-to": "Pix Certif is only accessible to Pix certification centres.",
        "errors": {
          "invitation-already-accepted": "This invitation has already been accepted.<br/>Please log in or contact the administrator of your Pix Certif space.",
          "invitation-was-cancelled": "This invitation is no longer valid.<br/>Please contact the administrator of your Pix Certif space.",
          "should-change-password": "You currently have a temporary password. <a href=\"{url}\">Reset your password here</a>."
        },
        "form": {
          "button": "Log in",
          "email-address": "Email address",
          "forgotten-password": "Forgot your password?",
          "mandatory-fields": "All fields are required.",
          "password": "Password"
        },
        "login": "Log in",
        "title": "Login"
      },
      "login-or-register": {
        "login-form": {
          "button": "Log in",
          "errors": {
            "status": {
              "403": "Access to this Pix Certif space is limited to invited members. Each Pix Certif space is managed by an administrator specific to the organisation using it. Please contact your administrator to get invited.",
              "404": "This email address doesn't match any Pix user.",
              "409": "This invitation has been already accepted or cancelled.",
              "412": "This invitation has already been accepted."
            }
          },
          "fields": {
            "email": {
              "error": "Your email address is invalid.",
              "label": "Email address"
            },
            "password": {
              "error": "Your password can't be empty.",
              "forgot-password": "Forgot your password?",
              "label": "Password"
            }
          },
          "login": "Log in",
          "title": "Already have an account?"
        },
        "register-form": {
          "button": "Sign up",
          "errors": {
            "default": "The service is temporarily unavailable. Please try again later.",
            "email-already-exists": "This email address is already registered, please login."
          },
          "fields": {
            "button": {
              "label": "Sign up"
            },
            "cgu": {
              "accept": "I agree to the",
              "and": "and",
              "aria-label": "Accept the terms of use of the Pix platform",
              "data-protection-policy": "personal data protection policy",
              "error": "You must agree to the Pix terms of use and personal data protection policy to create an account.",
              "terms-of-use": "Pix terms of use"
            },
            "email": {
              "error": "Please enter a valid email address.",
              "label": "Email address"
            },
            "first-name": {
              "error": "Please enter a first name.",
              "label": "First name"
            },
            "last-name": {
              "error": "Please enter a last name.",
              "label": "Last name"
            },
            "password": {
              "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
              "label": "Password"
            }
          },
          "title": "Sign up"
        },
        "title": "You have been invited to join the certification center {certificationCenterName}"
      },
      "session-supervising": {
        "candidate-in-list": {
          "authorized-to-resume": "Allowed to resume",
          "candidate-options": "Candidate's options",
          "display-candidate-options": "Display the candidate's options",
          "extra-time": "Extra time",
          "finished": "Finished",
          "ongoing": "In progress",
          "resume-test-modal": {
            "allow-test-resume": "Autoriser la reprise du test",
            "cancel-label": "Annuler et fermer la fenêtre de confirmation",
            "confirm": "Je confirme l'autorisation",
            "description": "Si le candidat a fermé la fenêtre de son test de certification (par erreur, ou à cause d'un problème technique) et est toujours présent dans la salle de test, vous pouvez lui permettre de reprendre son test à l'endroit où il l'avait quitté.",
            "error": "Une erreur est survenue, {firstName} {lastName} n'a a pu être autorisé à reprendre son test.",
            "instruction-with-name": "Autoriser {firstName} {lastName} à reprendre son test ?`",
            "success": "Succès ! {firstName} {lastName} peut reprendre son test de certification."
          },
          "start": "Start",
          "test-end-modal": {
            "cancel-label": "Annuler et fermer la fenêtre de confirmation",
            "description": "Attention : cette action entraîne la fin de son test de certification et est irréversible.",
            "end-assessment": "Finish the test",
            "error": "An error occured, {firstName} {lastName}'s test could not be finished.",
            "instruction-with-name": "Finish the test of {firstName} {lastName}?",
            "success": "Success! {firstName} {lastName}'s test has been finished."
          }
        },
        "candidate-list": {
          "authorized-to-start-candidates": "{authorizedToStartCandidates}/{totalCandidates} authorized {authorizedToStartCandidates, plural,one {candidate} other {candidates}}"
        },
        "header": {
          "access-code": "Access code (candidates)",
          "center-name": "Center name",
          "center-name-full": "Center name",
          "multiple-supervisor": "Supervisor(s)",
          "room": "Room",
          "session-id": "Session {sessionId}",
          "supervisor": "Supervisor"
        }
      },
      "sessions": {
        "detail": {
          "panel-clea": {
            "description": "Pour générer les certificats CléA numérique, téléchargez la liste des candidats, complétez-la, enregistrez-la au format .xlsx puis importez-la sur la ",
            "download-button": "Télécharger la liste des candidats",
            "error-message": "Une erreur est survenue lors du téléchargement du fichier. Veuillez réessayer ou contacter certif@pix.fr en indiquant le numéro de la session concernée.",
            "link-text": "plateforme du CléA numérique.",
            "title": "Des candidats ont obtenu le certificat CléA numérique"
          },
          "parameters": {
            "copy-access-code-number": "Copier le code du numéro de session",
            "copy-session-number": "Copier le code du numéro de session",
            "copy-session-password-supervisor": "Copier le mot de passe de session pour le surveillant",
            "finalization-info": "Les informations de finalisation de la session ont déjà été transmises aux équipes de Pix",
            "finalizing": "Finaliser la session",
            "observations": "Observations",
            "session-access-code": "Code d'accès",
            "session-candidate": "Candidat",
            "session-password": "Mot de passe de session",
            "session-update": "Modifier les informations de la session {sessionId}"
          }
        },
        "enrolled-candidates": {
          "with-details-description": "Liste des candidats inscrits à la session, triée par nom de naissance, avec un lien pour voir les détails du candidat et la possibilité de supprimer un candidat dans la dernière colonne.",
          "without-details-description": "Liste des candidats inscrits à la session, triée par nom de naissance, avec la possibilité de supprimer un candidat dans la dernière colonne."
        },
        "finalize": {
          "finished-test-list-description": "Liste des candidats qui ont fini leur test de certification, triée par nom de naissance, avec un lien pour ajouter un ou plusieurs signalements le cas échéant et une liste déroulante permettant de sélectionner la raison de l’abandon.",
          "unfinished-test-list-description": "Liste des candidats qui n’ont pas fini leur test de certification, triée par nom de naissance, avec un lien pour ajouter un ou plusieurs signalements le cas échéant et une liste déroulante permettant de sélectionner la raison de l’abandon."
        },
        "import": {
          "breadcrumb-import": "Import du modèle",
          "breadcrumb-summary": "Récapitulatif",
          "cancel": "Annuler l'import",
          "instructions-creation-list": "Vous pouvez créer des sessions :",
          "instructions-creation-list-with-candidates": "<strong>Avec candidats</strong>, complétez le modèle dans son intégralité,",
          "instructions-creation-list-without-candidates": "<strong>Sans candidat</strong>, complétez uniquement les informations des sessions.",
          "instructions-creation-title": "Pour créer des sessions ?",
          "instructions-edition-replace": "<strong>Pour remplacer</strong> une liste pré-existante dans le fichier modèle,",
          "instructions-edition-subscription": "<strong>Pour inscrire</strong> des candidats dans une session vide.",
          "instructions-edition-title": "Pour éditer la liste des candidats de sessions déjà créées ?",
          "instructions-edition-warning": "Attention à renseigner le numéro de session sans les autres informations de sessions (ex: date, heure, etc.) pour éviter les doublons lors de la création.",
          "session-import-template": "Télécharger (.csv)",
          "session-import-template-dl-error": "Une erreur s'est produite pendant le téléchargement",
          "session-import-template-label": "Télécharger le modèle vierge",
          "session-import-upload": "Importer (.csv)",
          "session-import-upload-label": "Importer le modèle complété",
          "title": "Créer/éditer plusieurs sessions"
        },
        "list": {
          "delete-modal": {
            "disclaimer": "Attention, cette action est irréversible.",
            "enrolled-candidates": "<span>{enrolledCandidatesCount} candidats</span> sont inscrits à cette session",
            "label": "Souhaitez-vous supprimer la session <span>{sessionId}</span> ?",
            "title": "Supprimer la session"
          },
          "empty": {
            "session-creation": "Créer une session",
            "session-multiple-creation": "Créer plusieurs sessionsx"
          },
          "header": {
            "session-creation": "Créer une session",
            "session-import-template": "Template d'import de sessions",
            "session-import-template-dl-error": "Une erreur s'est produite pendant le téléchargement",
            "session-import-template-label": "Télécharger le template d'import en masse",
            "session-import-upload": "Importer en masse",
            "session-multiple-creation-edition": "Créer/éditer plusieurs sessions",
            "title": "Sessions de certification"
          },
          "session-summary": {
            "actions": "Actions",
            "center-name": "Nom du site",
            "certification-session": "Session de certification",
            "date": "Date",
            "delete-session": "Supprimer la session {sessionSummaryId}",
            "effective-candidates": "Certifications passées",
            "empty-table": "Aucune session trouvée",
            "enrolled-candidates": "Candidats inscrits",
            "room": "Salle",
            "session-and-id": "Session {sessionId}",
            "session-number": "N° de session",
            "status": "Statut",
            "supervisor": "Surveillant(s)",
            "time": "Heure",
            "tooltip-label": "Au moins un candidat a rejoint la session, vous ne pouvez pas la supprimer."
          }
        },
        "new": {
          "actions": {
            "cancel-extra-information": "Cancel the session creation and return to the previous page",
            "create-session": "Create the session"
          },
          "address": "Location name",
          "date": "Starting date",
          "description": "Observations",
          "examiner": "Invigilator(s)",
          "extra-information": "Session scheduling | Pix Certif",
          "required-fields": "The fields marked by <abbr title=\"required\" class=\"mandatory-mark\">*</abbr> are required.",
          "room": "Room name",
          "time": "Starting time (local time)",
          "title": "Creation of a certification session"
        }
      },
      "team": {
        "first-name": "Prénom",
        "last-name": "Nom",
        "no-referer-section": {
          "description": "Le référent Pix est le contact privilégié des équipes de Pix. Il est le responsable du bon déroulement des sessions de certification.",
          "select-referer-button": "Désigner un référent",
          "title": "Aucun référent Pix désigné"
        },
        "pix-referer": "Référent Pix",
        "pix-referer-tooltip": "Le référent Pix est le contact privilégié des équipes de Pix. Il est le responsable du bon déroulement des sessions de certification.",
        "referer": "Référent",
        "select-referer-modal": {
          "cancel": "Annuler",
          "description": "Le Groupement d’intérêt public, sis 21-23 rue des Ardennes 75019 Paris PIX met en œuvre en tant que responsable de traitement un traitement de données à caractère personnel portant sur votre adresse email professionnel de référent Pix.<br />La finalité de ce traitement est la communication des informations nécessaires aux attributions du référent Pix au sein du centre de certification agréé. Le traitement de cette données repose sur l’exécution de la convention d’agrément de votre centre de certification.<br />Les données sont conservées pendant la durée de la convention d’agrément en vigueur entre le GIP Pix et le centre habilité auquel vous êtes rattaché.<br />Vous disposez de droits personnels, droits d'accès, de rectification et d’effacement des données, le droit de limitation ou d’opposition au traitement pour motif légitime et le droit d’introduire une réclamation auprès d’une autorité de contrôle ainsi que le droit de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel en cas de décès que vous pouvez exercer par courriel auprès de notre DPD en adressant votre demande à l’adresse électronique suivante : dpd@pix.fr .<br />Vous disposez de la possibilité d’introduire une réclamation auprès de Commission nationale Informatique et libertés « CNIL » à l’adresse suivante : 3 Place de Fontenoy – TSA 80715 – 75334 Paris Cedex 07.",
          "empty-option": "--Sélectionner--",
          "label": "Sélectionner un référent Pix",
          "title": "Sélection du référent Pix",
          "validate": "Valider"
        },
        "title": "Équipe",
        "update-referer-button": "Changer de référent"
      }
    }
  }], ["fr", {
    "common": {
      "actions": {
        "cancel": "Annuler",
        "close": "Fermer",
        "continue": "Continuer",
        "delete": "Supprimer",
        "quit": "Quitter",
        "update": "Modifier"
      },
      "api-error-messages": {
        "bad-request-error": "Les données que vous avez soumises ne sont pas au bon format.",
        "gateway-timeout-error": "Le service subit des ralentissements. Veuillez réessayer ultérieurement.",
        "internal-server-error": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
        "login-unauthorized-error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects.",
        "login-user-blocked-error": "Votre compte est bloqué car vous avez effectué trop de tentatives de connexion. Pour le débloquer, <a href=\"{url}\">contactez-nous</a>.",
        "login-user-temporary-blocked-error": "Vous avez effectué trop de tentatives de connexion. Réessayez plus tard ou cliquez sur <a href=\"{url}\">mot de passe oublié</a> pour le réinitialiser."
      },
      "form": {
        "mandatory-all-fields": "Tous les champs sont obligatoires."
      }
    },
    "current-lang": "fr",
    "navigation": {
      "footer": {
        "a11y": "Accessibilité : partiellement conforme",
        "current-year": "© {currentYear} écri+",
        "legal-notice": "Mentions légales"
      }
    },
    "pages": {
      "login": {
        "accessible-to": "L'accès à écri+Certif est limité aux centres de certification écri+.",
        "errors": {
          "invitation-already-accepted": "Cette invitation a déjà été acceptée.<br/>Connectez-vous ou contactez l’administrateur de votre espace écri+Certif.",
          "invitation-was-cancelled": "Cette invitation n’est plus valide.<br/>Contactez l’administrateur de votre espace écri+Certif.",
          "should-change-password": "Vous avez actuellement un mot de passe temporaire. Cliquez sur <a href=\"{url}\">mot de passe oublié</a> pour le réinitialiser."
        },
        "form": {
          "button": "Je me connecte",
          "email-address": "Adresse e-mail",
          "forgotten-password": "Mot de passe oublié ?",
          "mandatory-fields": "Tous les champs sont obligatoires.",
          "password": "Mot de passe"
        },
        "login": "Connectez-vous",
        "title": "Connectez-vous"
      },
      "login-or-register": {
        "login-form": {
          "button": "Se connecter",
          "errors": {
            "status": {
              "403": "L'accès à écri+Certif est limité aux membres invités. Chaque espace est géré par un administrateur écri+Certif propre à l'organisation qui l'utilise. Contactez-le pour qu'il vous y invite.",
              "404": "Cet email n'appartient à aucun utilisateur.",
              "409": "Cette invitation a déjà été acceptée ou annulée.",
              "412": "Cette invitation a déjà été acceptée"
            }
          },
          "fields": {
            "email": {
              "error": "Le champ adresse e-mail n’est pas valide.",
              "label": "Adresse e-mail"
            },
            "password": {
              "error": "Le champ mot de passe est obligatoire.",
              "forgot-password": "Mot de passe oublié ?",
              "label": "Mot de passe"
            }
          },
          "login": "Je me connecte",
          "title": "J'ai déjà un compte"
        },
        "register-form": {
          "button": "S'inscrire",
          "errors": {
            "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
            "email-already-exists": "Cette adresse e-mail est déjà enregistrée, connectez-vous."
          },
          "fields": {
            "button": {
              "label": "Je m'inscris"
            },
            "cgu": {
              "accept": "J'accepte les",
              "and": "et la",
              "aria-label": "Accepter les conditions d'utilisation d'écri+",
              "data-protection-policy": "politique de confidentialité",
              "error": "Vous devez accepter les conditions d’utilisation d'écri+ et la politique de confidentialité pour créer un compte.",
              "terms-of-use": "conditions d'utilisation d'écri+"
            },
            "email": {
              "error": "Le champ adresse e-mail n’est pas valide.",
              "label": "Adresse e-mail"
            },
            "first-name": {
              "error": "Le champ prénom est obligatoire.",
              "label": "Prénom"
            },
            "last-name": {
              "error": "Le champ nom est obligatoire.",
              "label": "Nom"
            },
            "password": {
              "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
              "label": "Mot de passe"
            }
          },
          "title": "Je m’inscris"
        },
        "title": "Vous êtes invité(e) à rejoindre le centre de certification {certificationCenterName}"
      },
      "session-supervising": {
        "candidate-in-list": {
          "authorized-to-resume": "Autorisé à reprendre",
          "candidate-options": "Options du candidat",
          "display-candidate-options": "Afficher les options du candidat",
          "extra-time": "Temps majoré",
          "finished": "Terminé",
          "ongoing": "En cours",
          "resume-test-modal": {
            "allow-test-resume": "Autoriser la reprise du test",
            "cancel-label": "Annuler et fermer la fenêtre de confirmation",
            "confirm": "Je confirme l'autorisation",
            "description": "Si le candidat a fermé la fenêtre de son test de certification (par erreur, ou à cause d'un problème technique) et est toujours présent dans la salle de test, vous pouvez lui permettre de reprendre son test à l'endroit où il l'avait quitté.",
            "error": "Une erreur est survenue, {firstName} {lastName} n'a a pu être autorisé à reprendre son test.",
            "instruction-with-name": "Autoriser {firstName} {lastName} à reprendre son test ?",
            "success": "Succès ! {firstName} {lastName} peut reprendre son test de certification."
          },
          "start": "Début",
          "test-end-modal": {
            "cancel-label": "Annuler et fermer la fenêtre de confirmation",
            "description": "Attention : cette action entraîne la fin de son test de certification et est irréversible.",
            "end-assessment": "Terminer le test",
            "error": "Une erreur est survenue, le test de {firstName} {lastName} n'a pas pu être terminé.",
            "instruction-with-name": "Terminer le test de {firstName} {lastName} ?",
            "success": "Succès ! Le test de {firstName} {lastName} est terminé."
          }
        },
        "candidate-list": {
          "authorized-to-start-candidates": "{authorizedToStartCandidates}/{totalCandidates} {authorizedToStartCandidates, plural,one {candidat sélectionné} other {candidats sélectionnés}}"
        },
        "header": {
          "access-code": "Code d'accès (candidats)",
          "center-name": "Site",
          "center-name-full": "Nom du site",
          "multiple-supervisor": "Surveillant(s)",
          "room": "Salle",
          "session-id": "Session {sessionId}",
          "supervisor": "Surveillant"
        }
      },
      "sessions": {
        "detail": {
          "panel-clea": {
            "description": "Pour générer les certificats CléA numérique, téléchargez la liste des candidats, complétez-la, enregistrez-la au format .xlsx puis importez-la sur la ",
            "download-button": "Télécharger la liste des candidats",
            "error-message": "Une erreur est survenue lors du téléchargement du fichier. Veuillez réessayer ou contacter certif@pix.fr en indiquant le numéro de la session concernée.",
            "link-text": "plateforme du CléA numérique.",
            "title": "Des candidats ont obtenu le certificat CléA numérique"
          },
          "parameters": {
            "copy-access-code-number": "Copier le code du numéro de session",
            "copy-session-number": "Copier le code du numéro de session",
            "copy-session-password-supervisor": "Copier le mot de passe de session pour le surveillant",
            "finalization-info": "Les informations de finalisation de la session ont déjà été transmises aux équipes d'écri+",
            "finalizing": "Finaliser la session",
            "observations": "Observations",
            "session-access-code": "Code d'accès",
            "session-candidate": "Candidat",
            "session-password": "Mot de passe de session",
            "session-update": "Modifier les informations de la session {sessionId}"
          }
        },
        "enrolled-candidates": {
          "with-details-description": "Liste des candidats inscrits à la session, triée par nom de naissance, avec un lien pour voir les détails du candidat et la possibilité de supprimer un candidat dans la dernière colonne.",
          "without-details-description": "Liste des candidats inscrits à la session, triée par nom de naissance, avec la possibilité de supprimer un candidat dans la dernière colonne."
        },
        "finalize": {
          "finished-test-list-description": "Liste des candidats qui ont fini leur test de certification, triée par nom de naissance, avec un lien pour ajouter un ou plusieurs signalements le cas échéant et une liste déroulante permettant de sélectionner la raison de l’abandon.",
          "unfinished-test-list-description": "Liste des candidats qui n’ont pas fini leur test de certification, triée par nom de naissance, avec un lien pour ajouter un ou plusieurs signalements le cas échéant et une liste déroulante permettant de sélectionner la raison de l’abandon."
        },
        "import": {
          "breadcrumb-import": "Import du modèle",
          "breadcrumb-summary": "Récapitulatif",
          "cancel": "Annuler l'import",
          "instructions-creation-list": "Vous pouvez créer des sessions :",
          "instructions-creation-list-with-candidates": "<strong>Avec candidats</strong>, complétez le modèle dans son intégralité,",
          "instructions-creation-list-without-candidates": "<strong>Sans candidat</strong>, complétez uniquement les informations des sessions.",
          "instructions-creation-title": "Pour créer des sessions ?",
          "instructions-edition-replace": "<strong>Pour remplacer</strong> une liste pré-existante dans le fichier modèle,",
          "instructions-edition-subscription": "<strong>Pour inscrire</strong> des candidats dans une session vide.",
          "instructions-edition-title": "Pour éditer la liste des candidats de sessions déjà créées ?",
          "instructions-edition-warning": "Attention à renseigner le numéro de session sans les autres informations de sessions (ex: date, heure, etc.) pour éviter les doublons lors de la création.",
          "session-import-template": "Télécharger (.csv)",
          "session-import-template-dl-error": "Une erreur s'est produite pendant le téléchargement",
          "session-import-template-label": "Télécharger le modèle vierge",
          "session-import-upload": "Importer (.csv)",
          "session-import-upload-label": "Importer le modèle complété",
          "title": "Créer/éditer plusieurs sessions"
        },
        "list": {
          "delete-modal": {
            "disclaimer": "Attention, cette action est irréversible.",
            "enrolled-candidates": "<span>{enrolledCandidatesCount} {enrolledCandidatesCount, plural,one {candidat} other {candidats}}</span> {enrolledCandidatesCount, plural,one {est inscrit} other {sont inscrits}} à cette session",
            "label": "Souhaitez-vous supprimer la session <span>{sessionId}</span> ?",
            "title": "Supprimer la session"
          },
          "empty": {
            "session-creation": "Créer une session",
            "session-multiple-creation": "Créer plusieurs sessions"
          },
          "header": {
            "session-creation": "Créer une session",
            "session-multiple-creation-edition": "Créer/éditer plusieurs sessions",
            "title": "Sessions de certification"
          },
          "session-summary": {
            "actions": "Actions",
            "center-name": "Nom du site",
            "certification-session": "Session de certification",
            "date": "Date",
            "delete-session": "Supprimer la session {sessionSummaryId}",
            "effective-candidates": "Certifications passées",
            "empty-table": "Aucune session trouvée",
            "enrolled-candidates": "Candidats inscrits",
            "room": "Salle",
            "session-and-id": "Session {sessionId}",
            "session-number": "N° de session",
            "status": "Statut",
            "supervisor": "Surveillant(s)",
            "time": "Heure",
            "tooltip-label": "Au moins un candidat a rejoint la session, vous ne pouvez pas la supprimer."
          }
        },
        "new": {
          "actions": {
            "cancel-extra-information": "Annuler la création de session et retourner vers la page précédente",
            "create-session": "Créer la session"
          },
          "address": "Nom du site",
          "date": "Date de début",
          "description": "Observations",
          "examiner": "Surveillant(s)",
          "extra-information": "Planification d’une session | écri+Certif",
          "required-fields": "Les champs marqués de <abbr title=\"obligatoire\" class=\"mandatory-mark\">*</abbr> sont obligatoires.",
          "room": "Nom de la salle",
          "time": "Heure de début (heure locale)",
          "title": "Création d’une session de certification"
        }
      },
      "team": {
        "first-name": "Prénom",
        "last-name": "Nom",
        "no-referer-section": {
          "description": "Le référent écri+ est le contact privilégié des équipes d'écri+. Il est le responsable du bon déroulement des sessions de certification.",
          "select-referer-button": "Désigner un référent",
          "title": "Aucun référent écri+ désigné"
        },
        "pix-referer": "Référent écri+",
        "pix-referer-tooltip": "Le référent écri+ est le contact privilégié des équipes d'écri+. Il est le responsable du bon déroulement des sessions de certification.",
        "referer": "Référent",
        "select-referer-modal": {
          "cancel": "Annuler",
          "description": "Le Groupement d’intérêt public, sis 21-23 rue des Ardennes 75019 Paris écri+ met en œuvre en tant que responsable de traitement un traitement de données à caractère personnel portant sur votre adresse email professionnel de référent écri+.<br />La finalité de ce traitement est la communication des informations nécessaires aux attributions du référent écri+ au sein du centre de certification agréé. Le traitement de cette données repose sur l’exécution de la convention d’agrément de votre centre de certification.<br />Les données sont conservées pendant la durée de la convention d’agrément en vigueur entre le GIP écri+ et le centre habilité auquel vous êtes rattaché.<br />Vous disposez de droits personnels, droits d'accès, de rectification et d’effacement des données, le droit de limitation ou d’opposition au traitement pour motif légitime et le droit d’introduire une réclamation auprès d’une autorité de contrôle ainsi que le droit de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel en cas de décès que vous pouvez exercer par courriel auprès de notre DPD en adressant votre demande à l’adresse électronique suivante : dpd@pix.fr .<br />Vous disposez de la possibilité d’introduire une réclamation auprès de Commission nationale Informatique et libertés « CNIL » à l’adresse suivante : 3 Place de Fontenoy – TSA 80715 – 75334 Paris Cedex 07.",
          "empty-option": "--Sélectionner--",
          "label": "Sélectionner le référent écri+",
          "title": "Sélection du référent écri+",
          "validate": "Valider"
        },
        "title": "Équipe",
        "update-referer-button": "Changer de référent"
      }
    }
  }]];
  _exports.default = _default;
});