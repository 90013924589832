define("@1024pix/pix-ui/components/pix-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-input">
    {{#if @label}}
      <label for={{this.id}} class="pix-input__label">
        {{#if @requiredLabel}}
          <abbr title={{@requiredLabel}} class="mandatory-mark" aria-hidden="true">*</abbr>
        {{/if}}
  
        {{@label}}
  
        {{#if @information}}
          <span class="pix-input__information">{{@information}}</span>
        {{/if}}
      </label>
    {{/if}}
  
    <div class="pix-input__container">
      <input
        id={{this.id}}
        class="pix-input__input--default {{this.validationStatusClassName}}"
        value={{@value}}
        aria-label={{this.ariaLabel}}
        aria-required="{{if @requiredLabel true false}}"
        required={{if @requiredLabel true false}}
        aria-describedby={{if (eq @validationStatus "error") "text-input-error"}}
        ...attributes
      />
  
      {{#if (eq @validationStatus "error")}}
        <FaIcon @icon="xmark" class="pix-input__error-icon" />
      {{/if}}
      {{#if (eq @validationStatus "success")}}
        <FaIcon @icon="check" class="pix-input__success-icon" />
      {{/if}}
    </div>
  
    {{#if (and (eq @validationStatus "error") @errorMessage)}}
      <p id="text-input-error" class="pix-input__error-message">{{@errorMessage}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "vkMbydWE",
    "block": "[[[10,0],[14,0,\"pix-input\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,0,[\"id\"]]],[14,0,\"pix-input__label\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"abbr\"],[15,\"title\",[30,2]],[14,0,\"mandatory-mark\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[14,0,\"pix-input__information\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"pix-input__container\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,1,[30,0,[\"id\"]]],[16,0,[29,[\"pix-input__input--default \",[30,0,[\"validationStatusClassName\"]]]]],[16,2,[30,4]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,\"aria-required\",[29,[[52,[30,2],true,false]]]],[16,\"required\",[52,[30,2],true,false]],[16,\"aria-describedby\",[52,[28,[37,1],[[30,5],\"error\"],null],\"text-input-error\"]],[17,6],[12],[13],[1,\"\\n\\n\"],[41,[28,[37,1],[[30,5],\"error\"],null],[[[1,\"      \"],[8,[39,2],[[24,0,\"pix-input__error-icon\"]],[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,5],\"success\"],null],[[[1,\"      \"],[8,[39,2],[[24,0,\"pix-input__success-icon\"]],[[\"@icon\"],[\"check\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,3],[[28,[37,1],[[30,5],\"error\"],null],[30,7]],null],[[[1,\"    \"],[10,2],[14,1,\"text-input-error\"],[14,0,\"pix-input__error-message\"],[12],[1,[30,7]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@label\",\"@requiredLabel\",\"@information\",\"@value\",\"@validationStatus\",\"&attrs\",\"@errorMessage\"],false,[\"if\",\"eq\",\"fa-icon\",\"and\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-input.hbs",
    "isStrictMode": false
  });
  const ERROR_MESSAGE = 'ERROR in PixInput component, you must provide @label or @ariaLabel params';
  const INPUT_VALIDATION_STATUS_MAP = {
    default: '',
    error: 'pix-input__input--error',
    success: 'pix-input__input--success'
  };
  class PixInput extends _component2.default {
    get id() {
      if (!this.args.id || !this.args.id.toString().trim()) {
        throw new Error('ERROR in PixInput component, @id param is not provided');
      }
      return this.args.id;
    }
    get label() {
      if (!this.args.label && !this.args.ariaLabel) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.label;
    }
    get ariaLabel() {
      if (!this.args.label && !this.args.ariaLabel) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.label ? null : this.args.ariaLabel;
    }
    get validationStatusClassName() {
      return INPUT_VALIDATION_STATUS_MAP[this.args.validationStatus] || '';
    }
  }
  _exports.default = PixInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixInput);
});